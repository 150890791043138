.sidebar {
    /* border-radius: 20px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100vh;
    width: 18vw;
    background-color: rgb(35, 36, 50);
    position: fixed;
}

.sidebar-list {
    height: auto;
    padding: 0;
    width: 100%;
}

.sidebar-list .row {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 65px;
    /* border: 1px solid black; */
    list-style-type: none;
    margin: 0%;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sidebar-list .row:hover {
    cursor: pointer;
    background-color: rgb(50, 50, 50);
}

.sidebar-list #active {
    background-color: rgb(50, 50, 50);
}

.row #icon {
    display: grid;
    flex: 30%;
    place-items: center;
}

.row #title {
    flex: 70%;
    margin-right: 10px;
}

.user {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 15vw;
    margin: 0 1.24vw;
    bottom: 0;
    margin-bottom: 20px;
    background-color: rgb(50, 50, 50);
    border-radius: 10px;
    margin-top: 10px;
    position: absolute;
}

.user #icon {
    color: white;
    display: grid;
    flex: 30%;
    place-items: center;
}

.user #title {
    flex: 70%;
    margin-right: 10px;
    color: white;

}

.sidebar-list #logo {
    display: flex;
    flex-direction: row;
    place-items: left;
    justify-content: center;
    padding-top: 20px;
    margin-bottom: 20px;
}