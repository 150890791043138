h1 {
    font-size: 2.0rem;
    align-self: flex-start;
    margin-bottom: 0.25rem;
}

p {
    color: rgb(92, 90, 85);
    font-weight: bold;
    font-size: 1.0rem;
    align-self: flex-start;
}

.login {
    display: flex;
    flex-direction: column;
}

.login-container {
    overflow: hidden;
    display: flex;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
    padding: 15rem;
    width: 300px;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.form-createAccount-container{
    flex-direction: column;
    position: absolute;
    transition: 1s;
    transform: translateX(1000px);
}

.form-login-container{
    position: absolute;
    flex-direction: column;
    transition: 1s;
    transform: translateX(0px);
}

.login-input {
    display: flex;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #8d71c582;
    color: aliceblue;
    width: 300px;
}

.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.label {
    margin-bottom: 0.5rem;
    color: aliceblue;
}

.divider {
    margin: 1rem 0;
    border-top: 3px solid rgb(132, 119, 153);
}
