.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 90%;
    max-width: 600px;
    background-color: rgba(40, 36, 52, 1);
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(0.9);
    opacity: 0;
    animation: popup-show 0.3s forwards;
}

@keyframes popup-show {
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 18px; 
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    transition: color 0.2s ease;
}

.close-btn:hover {
    color: #ff5a5f;
}
