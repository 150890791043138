.widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    height: 77vh;
    align-items: center;
    overflow-y: auto;
}

.widget {
    margin: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(135deg, #a12171, #8522b6);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.widget:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.widget:active {
    transform: scale(0.95);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page {
    overflow-y: auto;
    margin: 0 auto; /* Center the element horizontally */
    margin-top: 20px; /* Adjust the top margin to center vertically */
    padding: 10px;
    border: 2px dashed #aaa; /* Change border to dashed for visual indication */
    border-radius: 10px;
    width: 1000px; /* Increase the width to make the area bigger */
    min-height: 1000px; /* Increase the minimum height to make the area bigger */
    background-color: #231c1c;
    display: flex;
    justify-content: center;
    align-items: center;
}
