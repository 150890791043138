.card {
    background-color: #1f1f1f;
    border-radius: 8px;
    border: 1px solid #333;
    padding: 16px;
    margin: 8px;
    width: 220px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}

.card:hover {
    background-color: #333;
    transform: scale(1.05);
}

.card-icon {
    font-size: 24px;
    margin-bottom: 16px;
}

.card-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 8px;
    user-select: none;
}

.card-description {
    font-size: 14px;
    color: #b0b0b0;
    text-align: center;
    user-select: none;
}
