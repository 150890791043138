.form-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -8px;
}

.save-btn {
    margin-top: 16px;
    padding: 8px 16px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 35px;
    left: 92.5%;
}

.back-btn {
    margin-top: 16px;
    padding: 8px 16px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 35px;
    left: 20%;
}

/* .status-title {
    user-select: none;
    font-size: 1.5rem;
    position: fixed;
    bottom: 0;
    margin-bottom: 35px;
    text-align: center;

    align-items: center;
} */