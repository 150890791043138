.events {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.back-btn-alt {
    margin-top: 16px;
    padding: 8px 16px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;

    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 35px;
    left: 3%;
}