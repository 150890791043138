body, html {
  font-family: 'Arial', sans-serif;
  background: rgb(28, 28, 28);
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  color: whitesmoke;
  margin-top: 40px;
}

h2, h3, p  {
  color: whitesmoke;
}

a {
  /* display: inline-block; */
  /* margin: 10px; */
  padding: 10px;
  background-color: #8d34db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

a:hover {
  background-color: #3498db;
}

form {
  margin-top: 20px;
  text-align: left;
  max-width: 400px;
  margin: auto;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  background-color: #8d34db;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

button:hover {
  background-color: #492391;
}

table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #8d34db;
  color: #fff;
}

tr {
  background-color: #f2f2f2;
}

/* tr:nth-child(even) {
  background-color: #f2f2f2;
} */