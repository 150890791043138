.input {
    width: 10%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.panel-label {
    color: whitesmoke;
}